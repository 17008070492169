<template>
  <div class="BaseDetails">
    <ContainerCard :locationMsg="locationMsg" :width="64">
      <div class="titleInfo">
        <div class="groupRight">
          <span class="title1">{{ infoData.name }}</span>
          <div class="title2">
            <div class="top">
              <span class="word-btn">{{ infoData.level }}</span>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <span class="word">
                <span class="label">建设主体</span>
                <span class="value">{{ infoData.mainbody }}</span>
              </span>
          </div>
        </div>
        <div class="groupLeft">
          <el-image
            class="img1"
            referrerpolicy="no-referrer"
            v-if="infoData.photo"
            :src=" httpConfig.baseURL+infoData.photo"
          />
        </div>

        <div class="no-imgbox" v-if="!infoData.photo">
              <el-image class="no-img" :src=" defaultImg " alt="" />
        </div>
      </div>
      <div class="inline"></div>
      <div class="info">
        <div class="info-value">
          <div class="title2">
            <div class="top">
              <!-- <span class="word">
                <span class="label">建设主体</span>
                <span class="value">{{ infoData.mainbody }}</span>
              </span> -->
              <span class="word">
                <span class="label">可培训(鉴定)工种</span>
                <span class="value">{{ infoData.tawow }}</span>
              </span> 
              <span class="word">
                <span class="label">工位数</span>
                <span class="value">{{ infoData.stations_num }}</span>
              </span>
            </div>
            <!-- <div style="margin-top:30px;">
              <div class="top">
              <span class="word">
                <span class="label">工位数</span>
                <span class="value">{{ infoData.stations_num }}</span>
              </span>
              <span class="word">
                <span class="label">联系人</span>
                <span class="value">{{ infoData.contact }}</span>
              </span>
            </div>
            <div class="top">
              <span class="word">
                <span class="label">地址</span>
                <span class="value">{{ infoData.address }}</span>
              </span>
              <span class="word">
                <span class="label">联系电话</span>
                <span class="value">{{ infoData.mobile }}</span>
              </span>
            </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="inline"></div>
      <div class="info">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">基地描述</span>
        </div>
        <div class="info-value">{{infoData.memo}}</div>
      </div>
      <div class="inline"></div>
      <div class="info" style="padding-bottom: 0px">
        <div class="info-title">
          <img src="../../assets/images/信息资讯.png" alt="" />
          <span class="label">合作咨询</span>
        </div>
        <div class="info-consulting">
          <div class="tit">福建省产教融合综合服务平台</div>
          <div class="consulting-item">
            <!-- <span class="label">联系地址</span> -->
            <span class="label">联系人</span>
            <span class="value"
              >{{ infoData.contact }}</span
            >
          </div>
          <div class="consulting-item">
            <span class="label">联系电话</span>
            <span class="value">{{ infoData.mobile }}</span>
          </div>
          <div class="consulting-item">
            <!-- <span class="label">联系邮箱</span> -->
            <span class="label">地址</span>
            <span class="value">{{ infoData.address }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="info">
        <div class="infoTop">
          <el-image class="logo" :src="infoData.photo" alt="" />
          <div class="intros">
            <h2 class="name">{{ infoData.name }}</h2>

            <div class="intro">
              <p>建设主体：{{ infoData.mainbody }}</p>
              <p>级别：{{ infoData.level }}</p>
              <p>可培训(鉴定)工种：{{ infoData.tawow }}</p>
              <p>工位数：{{ infoData.stations_num }}</p>
              <p>地址：{{ infoData.address }}</p>
              <p>联系人：{{ infoData.contact }}</p>
              <p>联系电话：{{ infoData.mobile }}</p>
            </div>
          </div>
        </div>
        <div class="lineBox"></div>
        <div class="infomain">
          <p class="title">基地描述</p>
          <p class="artContent"
           style="line-height:20px;"
           v-html="infoData.memo"></p>
        </div>

        <Contact></Contact>
      </div> -->
    </ContainerCard>
  </div>
</template>
<script>
import Location from "../../components/Location.vue";
import Consult from "../../components/Consult.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import { getTrainingLabs } from "../../api/SixLibraries/Base/Base.js";
import { objDeepCopy } from "../../common/utils/tool";
import { getPlainText } from "../../common/utils/tool";
import Contact from "../../components/SixLibraries/Contact.vue";
import httpConfig from "../../common/utils/config";
import defaultImg from "../../assets/images/暂无数据默认图片.png";
export default {
  data() {
    return {
      defaultImg,
      httpConfig,
      infoData: {
        img: "https://tse1-mm.cn.bing.net/th/id/OIP-C.NHx7NfYNqUdH4kQG5argWwAAAA?w=182&h=131&c=7&r=0&o=5&dpr=1.25&pid=1.7",
        name: "高技能人才培训基地",
        body: "黎明职业大学、福建创想蓝图科技发展有限公司",
        level: "国家级",
        type: "跨境电商人才",
        num: "10",
        location: "中国北京市朝阳区朝阳门北大街22号",
        linkman: "莫老师",
        tel: "189****9999",
        description:
          "在合作办学上，周小华透露，目前新智公司正在与业界大咖中国保信集团对接。保信集团成功运营了北京曹妃甸国际职教城和贵州清镇职教城，有着丰富的经验。如果一切顺利，新智公司还计划与成都城投集团、中国保信集团深度合作，再成立一家管理运营公司，借成都城投集团的资金实力和保信集团的运营经验，参与园区的运营管理，统筹教育、社区、产业资源，推动职教学科建设、产业转型升级、企业创新转化等无缝对接、无障互通。",
      },
      type: [
        {
          label: "全部",
          value: "all",
        },
        { label: "国家级", value: "1" },
        { label: "省级", value: "2" },
        { label: "市级", value: "3" },
        { label: "县级", value: "4" },
        { label: "校级", value: "5" },
        { label: "其他", value: "6" },
      ],
      locationMsg: [
        { name: "七大库" },
        { name: "实训基地库", router: "base" },
        { name: "基地详情", router: "base/baseDetails" },
      ],
    };
  },
  components: { Contact, ContainerCard, Location, Consult },
  created() {
    let id = this.$route.query.id;
    this.getData(id);
  },
  methods: {
    async getData(id) {
      let res = await getTrainingLabs({ id: id });
      let tmp = objDeepCopy(res.data);
      const regex = new RegExp("<img", "gi");
      tmp.memo =
        res.data?.memo &&
        res.data?.memo.replace(regex, '<img style="max-width: 100%;"');
      this.type.find((item) => {
        if (item.value.toString() === res.data.level.toString()) {
          tmp.level = item.label;
        }
      });
      tmp.memo = getPlainText(getPlainText(tmp.memo));
      this.infoData = tmp;
    },
  },
};
</script>
<style scoped lang="scss">
.titleInfo {
  width: 756px;
  height: 182px;
  padding: 24px 0px 24px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .groupLeft {
    margin-right: 23px;
    z-index: 126;
    // width: 110px;
    // height: 110px;
    .img1 {
      z-index: 127;
      width: 184px;
      height: 110px;
      border-radius: 6px;
    }
  }
  .no-imgbox {
        margin-right: 23px;
        width: 150px;
        height: 90px;
        background: #F1F1F1;
        border-radius: 6px;
        text-align: center;
        line-height: 90px;
        .no-img {
        width: 24px;
        height: 24px;
      }
      }
  .groupRight {
    .title1 {
      width: 112px;
      height: 28px;
      color: rgba(16, 16, 16, 1);
      font-size: 24px;
      font-family: 思源黑体 CN Bold Bold;
      white-space: nowrap;
      line-height: 36px;
      text-align: left;
      font-weight: 600;
      // margin-left: 3px;
      margin-bottom: 12px;
    }

    .title2 {
      @mixin title2Font {
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        line-height: 18px;
        color: #8896a7;
      }
      .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .word {
          .label {
            @include title2Font;
            margin-right: 12px;
          }
          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
        .word-btn {
          padding: 3px 5px;
          box-sizing: border-box;
          color: #ffffff;
          line-height: 18px;
          font-family: 思源黑体 CN Regular;
          background-color: #2885ff;
          border-radius: 3px;
          margin-top: 12px;
          font-size: 12px
        }
      }
      .bottom {
        .wordBottom {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
    }
    .word {
          .label {
            font-size: 12px;
            font-family: 思源黑体 CN Regular;
            line-height: 18px;
            color: #8896a7;
            margin-right: 12px;
          }
          .value {
            font-size: 12px;
            font-family: 思源黑体 CN Regular;
            line-height: 18px;
            color: #404040;
            font-weight: 500;
          }
        }
    // .word {
    //   //   width: 178px;
    //   height: 16px;
    //   display: block;
    //   overflow-wrap: break-word;
    //   color: rgba(16, 16, 16, 1);
    //   font-size: 16px;
    //   font-family: SourceHanSansCN-Regular;
    //   white-space: nowrap;
    //   line-height: 16px;
    //   text-align: left;
    //   margin-top: 16px;
    // }
    .wordBottom {
      //   width: 338px;
      height: 16px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(16, 16, 16, 1);
      font-size: 16px;
      font-family: SourceHanSansCN-Regular;
      white-space: nowrap;
      line-height: 16px;
      text-align: left;
      margin-top: 8px;
    }
  }
}
.inline {
  //   width: 100%;
  height: 1px;
  width: 750px;
  background-color: rgba(216, 216, 216, 1);
  // margin-left: 30px;
  // margin-top: 24px;
}
.info {
  width: 756px;
  box-sizing: border-box;
  padding: 24px 30px 24px 0px;
  .info-title {
    margin-bottom: 12px;
    img {
      width: 8px;
      height: 10px;
      margin-right: 12px;
    }
    .label {
      font-size: 16px;
      font-family: 思源黑体 CN Medium;
      color: #101010;
      line-height: 24px;
    }
  }
  .info-item {
    display: inline-block;
    border-radius: 34.5px;
    padding: 10px 16px;
    border: 1px solid #f1f1f1;
    color: #101010;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    background-color: #fafafa;
    font-family: 思源黑体 CN Regular;
  }
  .info-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #404040;
    font-family: 思源黑体 CN Regular;
    text-align: justify;
    .title2 {
      @mixin title2Font {
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        line-height: 18px;
        color: #8896a7;
      }
      .top {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        .word {
          flex: 1;
          // margin-right: 300px;
          .label {
            @include title2Font;
            margin-right: 12px;
          }
          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
      .bottom {
        .wordBottom {
          .label {
            @include title2Font;
            margin-right: 12px;
          }

          .value {
            @include title2Font;
            color: #404040;
            font-weight: 500;
          }
        }
      }
    }
  }

  .info-consulting {
    .tit {
      background-color: #2885ff;
      padding: 6px;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 2px 8px 8px 8px;
      color: #ffffff;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
    }
    .consulting-item {
      margin-bottom: 20px;
      .label {
        font-size: 12px;
        line-height: 18px;
        color: #8896a7;
        margin-right: 12px;
      }

      .value {
        font-size: 12px;
        line-height: 18px;
        color: #404040;
      }
    }
  }
}
.info {
  border-radius: 5px;
  // margin-bottom: 20px;
  // margin-top: 24px;
  .infoTop {
    display: flex;
    justify-content: flex-start;
  }
  .logo {
    width: 268px;
    height: 184px;
    margin-right: 20px;
  }
  .intros {
    display: flex;
    flex-direction: column;
    /* width: 70%; */
    .name {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
    }
    .labelGroup {
      margin-top: 20px;
      width: 100%;
      display: flex;
      .item {
        margin-right: 8px;
        list-style: none;
        border-radius: 8px;
        border: 1px solid #3e9cff;
        padding: 7px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #3e9cff;
      }
    }
    .intro {
      width: 100%;
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      > p {
        margin-top: 20px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #101010;
        width: 50%;
      }
    }
  }
  .lineBox {
    margin-top: 24px;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
  }
  .infomain {
    margin-top: 24px;
    .title {
      font-size: 28px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #101010;
    }
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #101010;
      letter-spacing: 1px;
    }
  }
}
::v-deep .locationList{
  position: absolute;
  left: -27px;
}
@media screen and (max-width: 768px) {
}
</style>