import request from "../../../common/utils/request";

// 获取实训库信息
export function getTrainingLabs(params) {
  return request({
    url: "/itf/common/cjrh/traininglab/getTrainingLabById.json",
    method: "get",
    params:params,
  });
}


// 实训库搜索
export function searchBases(params) {
  return request({
    url: "/itf/common/cjrh/traininglab/getTrainingLabs.json",
    method: "get",
    params:params,
  });
}